<template>
  <div class="demo-container">
    <myBreadcrumb :nav="nav"></myBreadcrumb>
    <div id="viewer"></div>
    <!-- <ul class="item-container">
      <li
        v-for="(item, index) in itemArr"
        :key="index"
        @click="setViewer(item.img)"
      >
        {{`全景图${index+1}`}}
      </li>
    </ul> -->
  </div>
</template>

<script>
import { Viewer } from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'

import { MarkersPlugin } from 'photo-sphere-viewer/dist/plugins/markers'
import 'photo-sphere-viewer/dist/plugins/markers.css'
import { getVrDetail } from '@/api/index';


export default {
  name: 'demo',
  data() {
    return {
      viewer: '',
      markersPlugin: '',
      imgUrl: '',
      type: '',
      itemArr: [
        {
          img: 'https://p3-juejin.byteimg.com/tos-cn-i-k3u1fbpfcp/0ebbea0343bf44d2aabab3dc58aa4c3a~tplv-k3u1fbpfcp-watermark.awebp'
        },
        {
          img: 'https://p3-juejin.byteimg.com/tos-cn-i-k3u1fbpfcp/6df92ba39aa94aec889582df60d3d0d0~tplv-k3u1fbpfcp-watermark.awebp'
        },
        {
          img: 'https://p3-juejin.byteimg.com/tos-cn-i-k3u1fbpfcp/40135df7df034eb5a76daa76f26fe046~tplv-k3u1fbpfcp-watermark.awebp'
        },
        {
          img: 'https://img0.baidu.com/it/u=3377517910,1108839715&fm=26&fmt=auto'
        }
      ],
      nav: [
          {
              title: '首页',
              to: { path: '/' },
          },
          {
              title: '总分馆',
              to: { path: '/inCharge' },
          },
          {
              title: 'VR展示',
              to: { path: '/vrList', query: {type: '1' } },
          },
          {
              title: 'VR详情',
          },
      ],
    }
  },
  created() {
    this.getVrDetail(this.$route.query.id);
    this.initNav(this.$route.query.type);
  },
  methods: {
    initNav(type) {
      console.log('123' + type)
      this.type = type
      if (type == '2') {
          this.nav = [
            {
                title: '首页',
                to: { path: '/' },
            },
            {
                title: '非遗文化',
                to: { path: '//nonHeritage' },
            },
            {
                title: 'VR展示',
                to: { path: '/vrList', query: {type: '2' } },
            },
            {
                title: 'VR详情'
            },
        ]
      }
    },
    getVrDetail(id) {
      getVrDetail(id).then((res) => {
        this.imgUrl = res.data.url
        this.setViewer(this.imgUrl)
      });
      
    },
    setViewer(panorama) {
      if (this.viewer) try {
        this.viewer.destroy()
      } catch (e) {
        console.log(e)
        const viewer = document.querySelector('#viewer')
        viewer.removeChild(viewer.childNodes[0])
      }
      this.viewer = new Viewer({
        container: document.querySelector('#viewer'),
        panorama,
        size: {
          width: '886px',
          height: '554px'
        },
        plugins: [
          [
            MarkersPlugin,
            {
              markers: [
              ]
            }
          ]
        ]
      })
      this.viewer.once('ready', () => {
        console.log('ready')
       	// 自动旋转
        this.viewer.startAutorotate()
      })

      this.markersPlugin = this.viewer.getPlugin(MarkersPlugin)

      this.markersPlugin.on('select-marker', (e, marker) => {
        // 点击选中标注
        console.log('select-marker e:', e)
        console.log('marker:', marker)
        if (e.args[0].config.note) this.setViewer(this.itemArr[e.args[0].config.note].img)
      })

      this.viewer.on('click', (e, data) => {
        // 添加标注
        const num = 1 + Math.round(Math.random() * 2)
        console.log('num:', num)
        this.markersPlugin.addMarker({
          id: `mark${Math.random() * 10}`,
          tooltip: `213123
              <p>点击标注跳去${num + 1}全景图</p></p>
              <img style="width:100px;height:100px;" src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fp0.ssl.cdn.btime.com%2Ft0109595b67ee2b1409.jpg%3Fsize%3D640x400&refer=http%3A%2F%2Fp0.ssl.cdn.btime.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1638584229&t=abda6688f0689b74f3a20368e31f65c1" />
          `,
          html: `
            <p class="remark">点击标注跳去${num + 1}全景图</p>
            <p class="remark">这里是标记${
  Math.floor(data.longitude * 100) / 100
} - ${Math.floor(data.latitude * 100) / 100}
            </p>
            <img  src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F8d8c9a2823b7d858dd653159e3eb1c47b5032c32482e1-q2bcDa_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1638586036&t=475f829a3056851b1def0ec6de05a596" />
            `,
          longitude: data.longitude,
          latitude: data.latitude,
          anchor: 'center center',
          note: num
        })
        let configArr = Object.keys(this.markersPlugin.markers).map(
          (item) => this.markersPlugin.markers[item].config
        )
        localStorage.setItem('configArr', JSON.stringify(configArr))
      })
    }
  },
  mounted() {
    // console.log('11--' + this.imgUrl + '--22')
    // this.setViewer(this.imgUrl)
    // this.setViewer(this.itemArr[0].img)
    // const configArr = JSON.parse(localStorage.getItem('configArr'))
    // this.viewer.once('ready', () => {
    //   configArr.forEach((item) => {
    //     this.markersPlugin.addMarker(item)
    //   })
    // })
    this.initNav(this.$route.query.type);
  }
}
</script>

<style lang="scss" scoped>
.demo-container {
  position: relative;
  min-width: 1439px;
  margin: 0 auto;

  #viewer {
    margin: 0 auto;

    ::v-deep .psv-container {

      .psv-marker {
        border: 1px solid  white;
        padding: 10px;

        .remark {
          padding: 10px 15px;
          color: white;
        }
        img {
          width: 20px;
          height: 20px;
          margin: 0 auto;
          display: block;
        }
      }

      .psv-tooltip {
        .psv-tooltip-content {
          img {
            width: 100px;
            height: 100px;
            margin: 0 auto;
            display: block;
          }
        }
      }
    }
  }

  .item-container {
    text-align: center;
    margin-top: 20px;
    li {
      cursor: pointer;
      display: inline-block;
      padding: 10px 20px;
      &:hover {
        background-color: blue;
        color: white;
      }
    }
  }
}
</style>
